a {
    color: #0e044d !important;
}

h2 {
    margin: 0
}

::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-track {
    visibility: hidden;
}

::-webkit-scrollbar-thumb {
    background: #FFFFFF !important;
    border-radius: none;
}

::-webkit-scrollbar-thumb:hover {
    background: #0e044d;
}

table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
}

table thead tr {
    background-color: #0e044d !important;
}

table caption {
    font-size: 1.5em;
    margin: 0.5em 0 0.75em;
}

table tr {
    background-color: transparent;
    border: 1px solid #cecece;
    padding: 0.35em;
}

table tr:nth-child(odd) {
    background-color: #f4f6ff;
    border: 1px solid #cecece;
    padding: 0.35em;
}

table th, table td {
    padding: 0.625em;
}

table th {
    font-size: 0.85em;
}

.planChart {
    min-height: 300px;
    width: 100%;
}

.planlegend {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.label-top tspan {
    fill: white !important;
}

.MuiRadio-colorSecondary.Mui-checked {
    color: #FFFFFF !important;
}

.logo {
    width: 100%;
    max-width: 320px;
}

@media screen and (max-width: 600px) {
    table {
        border: 0;
    }
    table caption {
        font-size: 1.3em;
    }
    table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
    table tr {
        border-bottom: 3px solid #cecece;
        display: block;
        margin-bottom: 0.625em;
    }
    table td {
        border-bottom: 1px solid #cecece;
        display: block;
        font-size: 0.8em;
        text-align: right;
    }
    table td::before {
        /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }
    table td:last-child {
        border-bottom: 0;
    }
}

.recharts-cartesian-axis-tick-value tspan, .recharts-layer text {
    fill: #FFFFFF
}

.recharts-responsive-container {
    min-height: 300px;
}

@media (max-width: 959.95px) {
    .MuiTableCell-body {
        height: 64px !important;
    }
}